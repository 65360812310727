import React, { FC } from 'react'
import { Box, IconButton, Menu, MenuItem } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import { useMutation } from 'react-query'
import { useGetActiveSite } from 'modules/sites'
import { useNotify } from 'core/hooks'
import { httpService } from 'core/data'
import { slugifyValue } from 'modules/new-entity/utils'
import { EntityType } from '../types'

type Props = {
  entityType: EntityType
}

const getOption = (field: string, entityType: EntityType) => {
  if (Array.isArray(entityType.options)) {
    return null
  }
  return entityType.options[field]
}

export const BulkActionsButton: FC<Props> = ({ entityType }) => {
  const activeSite = useGetActiveSite()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const canExport = getOption('enable_export_csv', entityType)

  const notify = useNotify()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const exportCsvM = useMutation(
    () => {
      return httpService
        .get<Blob>(`/entities`, {
          responseType: 'blob',
          params: {
            entityType: entityType['@id'],
            pagination: false,
          },
          headers: {
            Accept: 'text/csv',
          },
        })
        .then((res) => res.data)
    },
    {
      onMutate: () => {
        handleClose()
        const { toastId } = notify('Preparing csv file', { type: 'loading' })
        return toastId
      },
      onSuccess: (data, vars, context) => {
        notify.toast?.dismiss(context)
        const objectUrl = URL.createObjectURL(data)
        handleClose()
        const link: HTMLAnchorElement = document.createElement('a')
        const siteName = activeSite?.name.toLowerCase().replaceAll(' ', '_')
        link.href = objectUrl
        link.download = `${slugifyValue(entityType.name)}-${siteName}.csv`
        link.click()
        URL.revokeObjectURL(objectUrl)
      },
      onError: () => {
        notify('Something went wrong', { type: 'error' })
      },
    }
  )

  if (!canExport) return null

  return (
    <Box ml={1}>
      <IconButton onClick={handleClick}>
        <MoreHoriz />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem button disabled={exportCsvM.isLoading} onClick={() => exportCsvM.mutate()}>
          Export csv
        </MenuItem>
      </Menu>
    </Box>
  )
}
